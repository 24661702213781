import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown } from 'reactstrap';
import { Link, } from "react-router-dom";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import Swal from 'sweetalert2';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ReactInputMask from 'react-input-mask';

import makeAnimated from "react-select/animated";

import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const animatedComponents = makeAnimated();

const SingleOptions = [
    { value: 'Choices 1', label: 'Choices 1' },
    { value: 'Choices 2', label: 'Choices 2' },
    { value: 'Choices 3', label: 'Choices 3' },
    { value: 'Choices 4', label: 'Choices 4' }
];

//import COmponents
import BaseExample from './GridTablesData';
import { TesteExport } from '../../components/phoneInputMask';
import { api } from "../../utils/api";
import { FilePondFile } from "filepond";
import { objectPropertiesHaveValue } from "../../utils/objectPropertiesHaveValue";
import { GenericObject } from "../../types/GenericObject";
import Flatpickr from 'react-flatpickr';
import { flatpickrLocale } from "../../utils/flatpickrLocale";
import CopyVoterRegisterLinkButton from "../../components/CopyVoterRegisterLinkButton";

export function Voters() {
    document.title = "Eleitores | Geld";

    const [showOtherGender, setShowOtherGender] = useState(false);
    const [otherGender, setOtherGender] = useState('');
    const [gender, setGender] = useState('');
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    //const [optionsGroupId, setOptionsGroupId] = useState([]);
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [selectSortRow, setselectSortRow] = useState('name');
    const [sortDirection, setsortDirection] = useState('asc');
    const [isFilter, setisFilter] = useState(false);
    const [hasEdit, sethasEdit] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [createdById, setCreatedById] = useState<Option[]>([]);
    const [RecommendationdBy, setRecommendationdBy] = useState<Option[]>([]);
    type Option = {
        id: number;
        name: string;
        created_by_recommendation: string;
    };
    const [formData, setFormData] = useState({
        neighborhood: '',
        electoral_zone: '',
        group: '',
        section: '',
        education: '',
        address: '',
        birth_date: '',
        birth_year: '',
        number_classification: '',
        profession: '',
        created_by: '',
        gender: '',
        otherGender: '',
        school: '',
        created_by_recommendation: '',
        voter_registration: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleGroupChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, group: event.target.value });
    };
    const handleEducationChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, education: event.target.value });
    };
    const handleBirthDateChange = (dateString: string) => {
        setFormData({ ...formData, birth_date: dateString });
    };
    const handleNumberClassificationChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, number_classification: event.target.value });
    };
    const handleOtherGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherGender(event.target.value);
    };
    const handleGenderChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, gender: event.target.value });
        const selectedGender = event.target.value;

        setGender(selectedGender);
        if (selectedGender === 'Outros') {
            setShowOtherGender(true);
        } else {
            setShowOtherGender(false);
        }
    };
    const handleCreatedByChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, created_by: event.target.value });
    };
    const handleRecommendationdByChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, created_by_recommendation: event.target.value });
    };
    const handleBlurYear = () => {
        if (Number(formData.birth_year) <= 1900) {
            setFormData({ ...formData, birth_year: '1900' });
        } else if (Number(formData.birth_year) >= 2099) {
            setFormData({ ...formData, birth_year: '2099' });
        }
    }

    const handleSubmitSearch = async (e: FormEvent) => {
        e.preventDefault();
        setisLoading(true);

        try {
            const response = await api.post('/searchVoters', {
                params: {
                    formData: formData, // Pass the current page directly
                    page: currentPage, // Pass the current page directly
                    perPage: rowsPerPage, // Pass the number of rows per page
                    selectSortRow: selectSortRow, // Pass the number of rows per page
                    sortDirection: sortDirection, // Pass the number of rows per page
                },
            });

            setTotalRows(response.data.totalCount);

            setData(response.data.data);
        } catch (error) {
            console.error(error);
        }

        setisLoading(false);
        setisFilter(true);

    };

    useEffect(() => {
        async function checkEditPermission() {
            const responseAccess = await api.get(`/getAuthIdAccessEdit`);
            if (!responseAccess.permissions.voters || !responseAccess.permissions.voters.access_type) {
                window.location.href = '/';
                return;
            }
            if (responseAccess.permissions.voters.access_type == "edit") {
                sethasEdit(true);
            }
        }

        async function loadGroups() {
            const responseGroupSelect = await api.get('/getGroup');
            setOptionsGroupId(responseGroupSelect.data);
            const responseCreatedBy = await api.get('/getUsersSelect');
            setCreatedById(responseCreatedBy.data);
            const responseRecommendationdBy = await api.get('/getRecommendationdBySelect');
            setRecommendationdBy(responseRecommendationdBy.data);
        }

        checkEditPermission();
        loadGroups();
    }, []);

    useEffect(() => {
        fetchData();

        // Remove o ano no datepicker
        if (document.querySelector('.numInputWrapper')) {
            document.querySelector('.numInputWrapper')!.className = 'numInputWrapper d-none'
        }
    }, [currentPage, rowsPerPage, selectSortRow, sortDirection, searchValue]);

    const fetchData = async () => {
        try {
            setisLoading(true);
            if (isFilter) {
                try {
                    const response = await api.post('/searchVoters', {
                        params: {
                            formData: formData, // Pass the current page directly
                            page: currentPage, // Pass the current page directly
                            perPage: rowsPerPage, // Pass the number of rows per page
                            selectSortRow: selectSortRow, // Pass the number of rows per page
                            sortDirection: sortDirection, // Pass the number of rows per page
                        },
                    })

                    setTotalRows(response.data.totalCount);

                    setData(response.data.data ?? []);
                } catch (error) {
                    console.error(error);
                }

                setisLoading(false);
                setisFilter(true);
            } else {
                const response = await api.get('/getVoters', {
                    params: {
                        page: currentPage, // Pass the current page directly
                        perPage: rowsPerPage, // Pass the number of rows per page
                        selectSortRow: selectSortRow, // Pass the number of rows per page
                        sortDirection: sortDirection, // Pass the number of rows per page
                        searchValue: searchValue
                    },
                });

                setTotalRows(response.data.totalCount);

                setData((response.data.data ?? []).map((item: any) => {
                    return { ...item, progress: (<ProgressBar style={{ width: '200px' }} label={`${item.progress}%`} now={item.progress} />) }
                }));
            }

            setisLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
    };
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setCurrentPage(1);
        setRowsPerPage(newRowsPerPage);
    };
    const handleSort = (selectSortRow: any, sortDirection: string) => {
        switch (selectSortRow.id) {
            case 1:
                setselectSortRow('id')
                break;
            case 2:
                setselectSortRow('name')
                break;
            case 3:
                setselectSortRow('classification')
                break;
            case 6:
                setselectSortRow('phone')
                break;
            case 7:
                setselectSortRow('neighborhood')
                break;
            default:
                break;
        }
        setsortDirection(sortDirection)
    }

    const handleExportExcel = async () => {
        let exportData: any[] = [];

        const response = await api.post('/searchVoters', {
            params: {
                formData: formData,
                selectSortRow: selectSortRow,
                sortDirection: sortDirection,
                noPagination: true,
            },
        });

        exportData = response.data.data;
        console.log(exportData);
        

        if (exportData.length === 0) {
            exportData.push({
                'ID': '',
                'CPF': '',
                'Nome': '',
                'Telefone': '',
                'Data Nascimento': '',
                'Nome da mãe': '',
                'Grupo': '',
                'Título de eleitor': '',
                'Zona Eleitoral': '',
                'Seção': '',
                'Escola': '',
                'CEP': '',
                'Endereço': '',
                'Número': '',
                'Complemento': '',
                'Bairro': '',
                'Cidade': '',
                'Estado (UF)': '',
                'ConfereTSE': '',
                'Comentário' : '',
                'Indicação' : '',
                'Contato por telefone' : '',
                'Comentário do contato' : ''
            })
        }
        const excelData = exportData.map((item) => ({
            'ID': item.id,
            'CPF': item.cpf,
            'Nome': item.nome,
            'Telefone': item.telefone,
            'Data Nascimento': item.birth_date,
            'Nome da mãe': item.mother_name,
            'Grupo': item.cargo,
            'Título de eleitor': item.voter_registration,
            'Zona Eleitoral': item.electoral_ward_id,
            'Seção': item.section,
            'Escola': item.school,
            'CEP': item.zip_code,
            'Endereço': item.address,
            'Número': item.number,
            'Complemento': item.address_complement,
            'Bairro': item.bairro,
            'Cidade': item.city,
            'Estado (UF)': item.state,
            'ConfereTSE': item.check_TSE,
            'Comentário': item.comentario,
            'Indicação': item.recomendacao,
            'Contato por telefone': item.is_contact_phone ? "Sim" : 'Não',
            'Comentário do contato': item.comment_contact ? item.comment_contact : '--',
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelDataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelDataBlob, 'Eleitores.xlsx');
    };

    const handleExportPdf = async () => {
        try {
            let params: GenericObject = {};

            let response: any;

            if (objectPropertiesHaveValue(formData)) {
                response = await api.post('/getVoters/pdf', {
                    params: {
                        formData: formData,
                        selectSortRow: selectSortRow,
                        sortDirection: sortDirection,
                        noPagination: true,
                        page: currentPage,
                        perPage: rowsPerPage,
                    },
                }, { responseType: 'arraybuffer' });
            } else {
                response = await api.post('/getVoters/pdf', {
                    page: currentPage,
                    perPage: rowsPerPage,
                    selectSortRow: selectSortRow,
                    sortDirection: sortDirection,
                }, { responseType: 'arraybuffer' });
            }

            var pdfDataBlob = new Blob([response], { type: 'application/pdf' });

            saveAs(pdfDataBlob, 'Eleitores.pdf');
        } catch (error: any) {
            Swal.fire('Erro', error.message, 'error')
            console.error(error)
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            {hasEdit && (
                                                <div className="d-flex justify-content-start">
                                                    <Link to="/registro-Eleitor">
                                                        <button type="button" className="btn btn-primary btn-animation waves-effect waves-light" data-text="Novo Eleitor">
                                                            <span className="d-flex flex-row align-items-center">Novo Eleitor <i className="la la-lg la-plus-square-o" style={{ marginLeft: "5px" }}></i></span>
                                                        </button>
                                                    </Link>
                                                </div>
                                            )}
                                        </Col>
                                        <Col>
                                            <div className="d-flex justify-content-center">
                                                <button type="button" onClick={handleClick} className="btn btn-primary btn-animation waves-effect waves-light" data-text="Filtro Avançado">
                                                    <span>Filtro Avançado</span>
                                                </button>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="d-flex justify-content-end">
                                                <CopyVoterRegisterLinkButton label="Link de Cadastro" />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody id="CardAdvancedSearch" className={`my-element ${isVisible ? '' : 'd-none'}`}>
                                    <Form onSubmit={handleSubmitSearch}>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="neighborhood" className="form-label">Bairro</label>
                                                    <input type="text" className="form-control" id="neighborhood"
                                                        name="neighborhood"
                                                        value={formData.neighborhood}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="electoral_zone" className="form-label">Zona Eleitoral</label>
                                                    <input type="text" className="form-control" id="electoral_zone"
                                                        name="electoral_zone"
                                                        value={formData.electoral_zone}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="group" className="form-label">Grupo</label>
                                                    <select className="form-select mb-3" id="handleGroupChange" aria-label="Default select example"
                                                        name="group"
                                                        value={formData.group}
                                                        onChange={handleGroupChange}>
                                                        <option value="">Selecione um Grupo</option>
                                                        {optionsGroupId.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="section" className="form-label">Seção</label>
                                                    <input type="text" className="form-control" id="section"
                                                        name="section"
                                                        value={formData.section}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="address" className="form-label">Endereço</label>
                                                    <input type="text" className="form-control" id="address"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="birth_date" className="form-label">Dia / Mês de Nascimento</label>
                                                    <ReactInputMask
                                                        id="birth_date"
                                                        name="birth_date"
                                                        className="form-control day-month-selector"
                                                        mask="99/99/9999" // Mask for date input
                                                        placeholder="DD/MM/AAAA" // Placeholder for date input
                                                        onChange={(e) => handleBirthDateChange(e.target.value)}
                                                        value={formData.birth_date}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="birth_year" className="form-label">Ano de Nascimento</label>
                                                    <input type="number"
                                                        className="form-control show-arrows"
                                                        id="birth_year"
                                                        name="birth_year"
                                                        value={formData.birth_year}
                                                        min={1900}
                                                        max={2099}
                                                        onChange={handleChange}
                                                        onBlur={(e) => handleBlurYear()}
                                                        inputMode="numeric"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="created_by" className="form-label">Cadastrado por</label>
                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                        name="created_by"
                                                        value={formData.created_by}
                                                        onChange={handleCreatedByChange}>
                                                        <option value="0">Selecione</option>
                                                        {createdById.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="created_by_recommendation" className="form-label">Indicado por</label>
                                                    <select className="form-select mb-3" aria-label="Default select example"
                                                        name="created_by_recommendation"
                                                        value={formData.created_by_recommendation}
                                                        onChange={handleRecommendationdByChange}>
                                                        <option value="0">Selecione</option>
                                                        {RecommendationdBy.map((option) => (
                                                            <option key={option.created_by_recommendation} value={option.created_by_recommendation}>
                                                                {option.created_by_recommendation}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="school" className="form-label">Escola</label>
                                                    <input type="text" className="form-control" id="school"
                                                        name="school"
                                                        value={formData.school}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="voter_registration" className="form-label">Título de eleitor</label>
                                                    <input type="text" className="form-control" id="voter_registration"
                                                        name="voter_registration"
                                                        value={formData.voter_registration}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light">Pesquisar</button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0 flex-grow-1">Eleitores</h4>
                                </CardHeader>

                                <CardBody>
                                    <BaseExample tableData={data}
                                        rowsPerPage={10}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        total={totalRows}
                                        isLoading={isLoading}
                                        handleSort={handleSort}
                                        onExportExcel={handleExportExcel}
                                        onExportPdf={handleExportPdf}
                                        setSearchValue={setSearchValue}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
{/* <CardBody>
    <BaseExample tableData ={data}/>
</CardBody> */}