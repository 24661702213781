import React, { useEffect, useState, ChangeEvent, FormEvent, useRef  } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form, Input, FormFeedback } from 'reactstrap';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import Swal from 'sweetalert2';
import ReactInputMask from 'react-input-mask';

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

//import COmponents
import { api } from "../../utils/api";
import Flatpickr from 'react-flatpickr';
import { flatpickrLocale } from "../../utils/flatpickrLocale";
import { removeNonAlphanumeric } from "../../utils/removeNonAlphanumeric";
import { GenericObject } from "../../types/GenericObject";
import { format } from "date-fns";

export function NewVoters() {
    const navigate = useNavigate();
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [filesTSE, setFilesTSE] = useState<File[]>([]);
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);
    const [responseData, setResponseData] = useState(null);
    const [createdByType, setCreatedByType] = useState<'user' | 'free' | 'recommendation'>('user');
    const [usersSelect, setUsersSelect] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [selectedGroup, setSelectedGroup] = useState<any>(null);
    const [lastSearchedCpf, setLastSearchedCpf] = useState('');
    const [userLogged, setUserLogged] = useState<any | null>(null);
    const [createdAt, setCreatedAt] = useState(new Date());
    const [isContactPhoneChecked, setContactPhoneChecked] = useState(false);
    const [isCommentPhoneVisible, setIsCommentPhoneVisible] = useState(false);

    const [check_TSE, setcheck_TSE] = useState<'N' | 'Y'>('N');
    const [isSearchingCpf, setIsSearchingCpf] = useState(false);

    type Option = {
        id: number;
        name: string;
    };
    const [formData, setFormData] = useState({
        cpf: '',
        name: '',
        phone: '',
        birth_date: '',
        mother_name: '',
        group_id: '',
        voter_registration: '',
        electoral_ward_id: '',
        section: '',
        zip_code: '',
        address: '',
        number: '',
        address_complement: '',
        neighborhood: '',
        city: '',
        state: '',
        comments: '',
        number_classification: 0,
        created_by: 0,
        created_by_description: '',
        created_by_recommendation:'',
        school: '',
        check_TSE: '',
        switchContactPhone : false,
        commentsContactPhone : ''
    });
    const [formValidationMessages, setFormValidationMessages] = useState({
        cpf: '',
        name: '',
        phone: '',
        birth_date: '',
        mother_name: '',
        address: '',
        zip_code: '',
        voter_registration: '',
        electoral_ward_id: '',
        school: '',
        section: '',
        created_by_recommendation: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    };

    // Validação de duplicidade: verifica se o CPF já existe
    const isCpfRegistered = async (cpf: string): Promise<boolean> => {
        try {
            const response = await api.get(`/isCpfRegistered/${cpf}`);
            return response.data as boolean; 
        } catch (error) {
            console.error("Erro ao verificar CPF:", error);
            return false; 
        }
    }

    const handleChangeCpf = async (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, cpf: event.target.value });

        if (isSearchingCpf) {
            return;
        }

        let isRegistered: boolean;
        isRegistered = await isCpfRegistered(removeNonAlphanumeric(event.target.value))

        // validação de duplicidade: verifica se CPF já existe
        if(isRegistered && event.target.value.length >= 14){
            console.error(`Já existe um cadastro com esse CPF`);
            Swal.fire('Erro', `Já existe um cadastro com esse CPF`, 'error');
            setFormData({ ...formData, cpf: '' });
        }

        if (event.target.value.length < 14 || event.target.value === lastSearchedCpf) {
            return;
        }

        try {
            setIsSearchingCpf(true);
            setLastSearchedCpf(event.target.value);

            const cpfResponse = await api.get(`/getCpf/${removeNonAlphanumeric(event.target.value)}`);

            if (cpfResponse.status && cpfResponse.nome !== 'Test Token') {
                let extraInformations: GenericObject = {};

                if (cpfResponse.nascimento) {
                    const day = cpfResponse.nascimento.substring(0, 2);
                    const month = cpfResponse.nascimento.substring(3, 5);
                    const year = cpfResponse.nascimento.substring(6);
                    extraInformations.birth_date = `${day}/${month}/${year}`;
                }

                if (cpfResponse.mae) {
                    extraInformations.mother_name = String(cpfResponse.mae).toUpperCase();
                }

                if (cpfResponse.genero) {
                    extraInformations.gender = cpfResponse.genero === 'F' ? 'Feminino' : (cpfResponse.genero === 'M' ? 'Masculino' : 'Outros');
                }

                setFormData((prevState) => ({
                    ...prevState,
                    name: String(cpfResponse.nome || '').toUpperCase(),
                    ...extraInformations,
                }));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSearchingCpf(false);
        }
    };

    const handleBirthDateChange = (dateString: string) => {
        setFormData({ ...formData, birth_date: dateString });
    };

    // const handleGroupChange = (event: ChangeEvent<HTMLSelectElement>) => {
    //     setFormData({ ...formData, group_id: event.target.value });
    // };
    const handleGroupChange = (option: any) => {
        setSelectedGroup(option ?? null);
        setFormData({ ...formData, group_id: option?.value ?? '' });
    };

    const handleContactPhoneSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactPhoneChecked(event.target.checked);
        setFormData({ ...formData, switchContactPhone: event.target.checked });

        setIsCommentPhoneVisible(event.target.checked)
    };

    const handleChangeCep = async (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, zip_code: event.target.value });

        if (event.target.value.length <= 8) {
            return;
        }

        const responseGet = await api.get('/getCep/' + event.target.value);
        if (responseGet != "failed") {
            setFormData((prevState) => ({
                ...prevState,
                address: responseGet.logradouro?.toUpperCase() ?? '',
                number: formData.number ?? '',
                neighborhood: responseGet.bairro?.toUpperCase() ?? '',
                city: responseGet.localidade?.toUpperCase() ?? '',
                state: responseGet.uf?.toUpperCase() ?? '',
                address_complement: responseGet.complemento?.toUpperCase() ?? '',
            }));

        }
    };

    const handleUserChange = (option: any) => {
        setSelectedUser(option ?? null);
        setFormData({ ...formData, created_by: option?.value ?? '', created_by_description: '' });
    };

    useEffect(() => {
        async function getUsers() {
            const responseUsers = await api.get('/getUsersSelect');
            setUsersSelect(responseUsers.data);
        }

        getUsers();
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            // pega usuario logado
            const responseUser = await api.get(`/getUserLogged`);
            setUserLogged(responseUser.data ?? '')

            const responseGroupSelect = await api.get('/getGroup');
            setOptionsGroupId(responseGroupSelect.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const isValid = validateFormData();
        // console.log(isValid);
        if (!isValid) {
            Swal.fire('Erro', `Verifique os erros do formulário`, 'error');
            return false;
        }

        var formFile = new FormData();
        if (files.length) {
            formFile.append('image', files[0], files[0].name);
        }
        if (filesTSE.length) {
            formFile.append('imageTSE', filesTSE[0], filesTSE[0].name);
        }
        Object.entries(formData).forEach(([key, value]) => {
            formFile.append(key, String(value));
        });


        api.post('/createVoters', formFile)
            .then(response => {
                if (response.error && response.error == "errorCpf") {
                    Swal.fire('Erro', `OPS JA EXISTE OUTRO ELEITOR COM ESSE CPF! (ELEITOR ID ${response.voterExist.id})`, 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, cpf: 'Já existe outro eleitor com esse CPF' }));
                } else if (response.error && response.error == "errorNameAndPhone") {
                    Swal.fire('Erro', `OPS JA EXISTE OUTRO ELEITOR COM ESSE NOME E TELEFONE! (ELEITOR ID ${response.voterExist.id})`, 'error');
                    setFormValidationMessages((prevState) => ({
                        ...prevState,
                        name: 'Já existe outro eleitor com esse nome e telefone',
                        phone: 'Já existe outro eleitor com esse nome e telefone'
                    }));
                } else if (response.error && response.error == "errorPhone") {
                    Swal.fire('Erro', `Falha na criação de eleitor! <br> Telefone Existente! (ELEITOR ID ${response.voterExist.id})`, 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, phone: 'Já existe outro eleitor com esse telefone' }));
                } else if (response.error && response.error == "errorRegistration") {
                    Swal.fire('Erro', `OPS JA EXISTE OUTRO ELEITOR COM ESSE TÍTULO DE ELEITOR! (ELEITOR ID ${response.voterExist.id})`, 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, voter_registration: 'Já existe outro eleitor com esse título de eleitor' }));
                }else if (response.error && response.error == "error") {
                    Swal.fire('Erro', `Erro na criação do eleitor`, 'error');
                    console.log(response.erroMsg);
                } else {
                    console.log(response);
                    Swal.fire('Sucesso', 'Eleitor criado com Sucesso!', 'success').then(() => {
                        window.location.href = window.location.href;
                    });
                }

            })
            .catch(error => {
                // Handle the error response
                Swal.fire('Erro', 'Falha na criação de eleitor!', 'error');
            });
    };

    function validateFormData() {
        const errors = {
            cpf: '',
            name: '',
            phone: '',
            birth_date: '',
            mother_name: '',
            address: '',
            zip_code: '',
            email: '',
            voter_registration: '',
            electoral_ward_id: '',
            school: '',
            section: '',
            created_by_recommendation: '',
        };

        let hasError = false;

        if (!formData.name) {
            hasError = true;
            errors.name = 'O nome é obrigatório';
        }
        // if (!formData.cpf) {
        //     hasError = true;
        //     errors.cpf = 'O CPF é obrigatório';
        //     console.log('O CPF é obrigatório');
        // }
        if (!formData.phone) {
            hasError = true;
            errors.phone = 'O telefone é obrigatório';
        }
        if (!formData.birth_date) {
            hasError = true;
            errors.birth_date = 'A data de nascimento é obrigatória';
        }
        if (!formData.mother_name) {
            hasError = true;
            errors.mother_name = 'O nome da mãe é obrigatório';
        }
        if (!formData.address) {
            hasError = true;
            errors.address = 'O endereço é obrigatório';
        }
        if (!formData.zip_code) {
            hasError = true;
            errors.zip_code = 'O CEP é obrigatório';
        }
        if (!formData.electoral_ward_id) {
            hasError = true;
            errors.electoral_ward_id = 'A Zona Eleitoral é obrigatória';
        }
        if (!formData.school) {
            hasError = true;
            errors.school = 'A Escola é obrigatória';
        }
        if (!formData.section) {
            hasError = true;
            errors.section = 'A Seção é obrigatória';
        }
        if (!formData.voter_registration) {
            hasError = true;
            errors.voter_registration = 'O Título de eleitor é obrigatório';
        }
        if (!formData.created_by_recommendation) {
            hasError = true;
            errors.created_by_recommendation = 'A Indicação é obrigatória';
        }

        setFormValidationMessages(errors);
        return !hasError;
    }

    document.title = "Eleitores | Geld";

    const handleRatingChange = (index: number) => {
        setRating(index);
        setFormData({ ...formData, number_classification: index });
    };

    function handleClickChangeCreatedByType(type: 'user' | 'free' | 'recommendation') {
        let newType: 'user' | 'free' | 'recommendation';

        switch (type) {
            case 'user':
                newType = 'user';
                break;
            case 'free':
                newType = 'free';
                break;
            case 'recommendation':
                newType = 'recommendation';
                break;
            default:
                newType = 'user'; // default to 'user' if an invalid type is provided
                break;
        }
        setCreatedByType(newType);
        setFormData({ ...formData, created_by: 0, created_by_description: '' });
        setSelectedUser(null);
    }
    function handlecheck_TSE(check_TSE: 'N' | 'Y'){
        const newCheckTSE = check_TSE === 'N' ? 'N' : 'Y';
        setFormData({ ...formData, check_TSE: newCheckTSE });
        setcheck_TSE(newCheckTSE);
    }
    const StarRating = () => {

        var divStyle = {
            fontSize: '25px !important'
        };
        return (
            <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on " : "off"}
                            onClick={() => handleRatingChange(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span className="star" style={divStyle}>&#9733;</span>
                        </button>
                    );
                })}
            </div>
        );
    };

    const customStylesSelect = {
        control: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#eff2f7' : 'white', // Cor de fundo quando desativado
            borderColor: state.isDisabled ? '#dcdcdc' : '#ced4da', // Cor da borda quando desativado
            color: state.isDisabled ? '#a0a0a0' : 'black', // Cor do texto quando desativado
            boxShadow: 'none', // Remove a sombra padrão
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Cor de fundo do menu
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#b1a7ce' : 'white', // Cor de fundo da opção selecionada
            color: state.isSelected ? 'white' : 'black', // Cor do texto da opção selecionada
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#a0a0a0', // Cor do texto do placeholder
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: 'black', // Cor do texto selecionado
        })
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Novo Eleitor</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <span className="text-danger">* Campos obrigatórios</span>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4}></Col>
                                            <Col lg={4} className="d-flex justify-content-center">
                                                <FilePond
                                                    className='filepond filepond-container filepond-photo'
                                                    files={files}
                                                    onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                    allowReorder={true}
                                                    allowMultiple={false}
                                                    labelIdle={`
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <img src="/icons/upload.png" />
                                                            <b style="color: #888">Arraste ou clique para upload da foto</b>
                                                        </div>
                                                    `}
                                                    id="fileUpload"
                                                    name="fileUpload"
                                                    acceptedFileTypes={['image/*']} // Restrict to image files
                                                    stylePanelAspectRatio={(300 / 440).toString()} // Convert the aspect ratio to a string
                                                />
                                            </Col>
                                            <Col lg={4}></Col>
                                        </Row>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="cpf" className="form-label">
                                                        CPF
                                                    </label>
                                                    <Cleave
                                                        placeholder="Buscar pelo CPF"
                                                        options={{
                                                            delimiters: ['.', '.', '-'],
                                                            blocks: [3, 3, 3, 2],
                                                            numericOnly: true,
                                                        }}
                                                        className={`form-control ${!!formValidationMessages.cpf ? 'is-invalid' : ''}`}
                                                        id="cpf"
                                                        name="cpf"
                                                        value={formData.cpf}
                                                        onChange={handleChangeCpf}
                                                    />
                                                    {!!formValidationMessages.cpf && (
                                                        <FormFeedback>
                                                            {formValidationMessages.cpf}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="inputName" className="form-label">
                                                        Nome <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputName"
                                                        placeholder="Digite seu Nome"
                                                        invalid={!!formValidationMessages.name}
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                    />
                                                    {!!formValidationMessages.name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="cleave-phone" className="form-label">
                                                        Telefone <span className="text-danger">*</span>
                                                    </label>
                                                    <Cleave
                                                        placeholder="xx xxxxx xxxx"
                                                        options={{
                                                            delimiter: ' ',
                                                            blocks: [2, 5, 4] // [2 digits, 5 digits, 4 digits]
                                                        }}
                                                        className={`form-control ${!!formValidationMessages.phone ? 'is-invalid' : ''}`}
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                    />
                                                    {!!formValidationMessages.phone && (
                                                        <FormFeedback>
                                                            {formValidationMessages.phone}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="birth_date" className="form-label">
                                                        Data de Nascimento <span className="text-danger">*</span>
                                                    </label>
                                                    <ReactInputMask
                                                        id="birth_date"
                                                        name="birth_date"
                                                        className={`form-control ${!!formValidationMessages.birth_date ? 'is-invalid' : ''}`}
                                                        mask="99/99/9999" // Mask for date input
                                                        placeholder="DD/MM/AAAA" // Placeholder for date input
                                                        onChange={handleChange}
                                                        value={formData.birth_date}
                                                    />
                                                    {!!formValidationMessages.birth_date && (
                                                        <FormFeedback>
                                                            {formValidationMessages.birth_date}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="mother_name" className="form-label">
                                                        Nome da mãe <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="mother_name"
                                                        placeholder="Digite o nome"
                                                        name="mother_name"
                                                        value={formData.mother_name}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.mother_name}
                                                    />
                                                    {!!formValidationMessages.mother_name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.mother_name}
                                                        </FormFeedback>
                                                    )}
                                                </div> 
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                <label htmlFor="handleGroupChange" className="form-label">Grupo</label>
                                                <Select
                                                        options={optionsGroupId.map((option) => ({
                                                            value: option.id,
                                                            label: option.name,
                                                        }))}
                                                        value={selectedGroup}
                                                        onChange={handleGroupChange}
                                                        placeholder="Selecione um Grupo"
                                                        noOptionsMessage={({ inputValue }) => 'Nenhum resultado encontrado'}
                                                        loadingMessage={({ inputValue }) => "Carregando"}
                                                        styles={customStylesSelect}
                                                    />

                                                    {/* <label htmlFor="handleGroupChange" className="form-label">Grupo</label>
                                                    <select className="form-select mb-3" id="handleGroupChange" aria-label="Default select example"
                                                        name="group"
                                                        value={formData.group_id}
                                                        onChange={handleGroupChange}>
                                                        <option value="">Selecione um Grupo</option>
                                                        {optionsGroupId.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select> */}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="voter_registration" className="form-label">
                                                        Título de eleitor <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text" className="form-control"
                                                        id="voter_registration"
                                                        placeholder="Digite seu Titulo Eleitoral"
                                                        name="voter_registration"
                                                        value={formData.voter_registration}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.voter_registration}
                                                    />
                                                    {!!formValidationMessages.voter_registration && (
                                                        <FormFeedback>
                                                            {formValidationMessages.voter_registration}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="electoral_ward_id" className="form-label">
                                                        Zona Eleitoral <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="text" className="form-control" id="electoral_ward_id" placeholder="Digite sua Zona"
                                                        name="electoral_ward_id"
                                                        value={formData.electoral_ward_id}
                                                        onChange={handleChange} 
                                                        invalid={!!formValidationMessages.electoral_ward_id}
                                                    />
                                                    {!!formValidationMessages.electoral_ward_id && (
                                                        <FormFeedback>
                                                            {formValidationMessages.electoral_ward_id}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="section" className="form-label">
                                                        Seção <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="text" className="form-control" id="section" placeholder="Digite sua Referência"
                                                        name="section"
                                                        value={formData.section}
                                                        onChange={handleChange} 
                                                        invalid={!!formValidationMessages.section}
                                                    />
                                                    {!!formValidationMessages.section && (
                                                        <FormFeedback>
                                                            {formValidationMessages.section}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="school" className="form-label">
                                                        Escola <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="text" className="form-control" id="school" placeholder="Digite sua Escola"
                                                        name="school"
                                                        value={formData.school}
                                                        onChange={handleChange} 
                                                        invalid={!!formValidationMessages.section}
                                                    />
                                                    {!!formValidationMessages.school && (
                                                        <FormFeedback>
                                                            {formValidationMessages.school}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="created_by_recommendation" className="form-label">
                                                        Indicação
                                                    </label>
                                                    <Input type="text" className="form-control" id="created_by_recommendation"
                                                        placeholder="Digite a Indicação"
                                                        name="created_by_recommendation"
                                                        value={formData.created_by_recommendation}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.section}
                                                    />
                                                    
                                                    {!!formValidationMessages.created_by_recommendation && (
                                                        <FormFeedback>
                                                            {formValidationMessages.created_by_recommendation}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="zip_code" className="form-label">
                                                        CEP <span className="text-danger">*</span>
                                                    </label>
                                                    <Cleave
                                                        placeholder="Buscar CEP"
                                                        options={{
                                                            delimiter: '-',
                                                            blocks: [5, 3],
                                                            numericOnly: true,
                                                        }}
                                                        className={`form-control ${!!formValidationMessages.zip_code ? 'is-invalid' : ''}`}
                                                        id="zip_code"
                                                        name="zip_code"
                                                        value={formData.zip_code}
                                                        onChange={handleChangeCep}
                                                    />
                                                    {!!formValidationMessages.zip_code && (
                                                        <FormFeedback>
                                                            {formValidationMessages.zip_code}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <label htmlFor="address" className="form-label">
                                                        Endereço <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.address}
                                                    />
                                                    {!!formValidationMessages.address && (
                                                        <FormFeedback>
                                                            {formValidationMessages.address}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="number" className="form-label">Número</label>
                                                    <input type="number" className="form-control" id="number"
                                                        name="number"
                                                        value={formData.number}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="address_complement" className="form-label">Complemento</label>
                                                    <input type="text" className="form-control" id="address_complement"
                                                        name="address_complement"
                                                        value={formData.address_complement}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="neighborhood" className="form-label">Bairro</label>
                                                    <input type="text" className="form-control" id="neighborhood"
                                                        name="neighborhood"
                                                        value={formData.neighborhood}
                                                        onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="city" className="form-label">
                                                        Cidade
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="city"
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="state" className="form-label">
                                                        Estado (UF)
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="state"
                                                        name="state"
                                                        value={formData.state}
                                                        onChange={handleChange}
                                                        maxLength={2}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="check_TSE" className="form-label">Confere TSE</label>
                                                    <select className="form-select mb-3" id="check_TSE" aria-label="Default select example"
                                                        name="check_TSE"
                                                        value={formData.check_TSE}
                                                        onChange={(e) => handlecheck_TSE(e.target.value as 'N' | 'Y')}>
                                                            
                                                        <option value="N">Não</option>   {/* Add options for 'no' */}
                                                        <option value="Y">Sim</option> {/* Add options for 'yes' */}
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="switchContactPhone" className="form-label"></label>
                                                    
                                                    <div className="form-check">
                                                        <input className="form-check-input" onChange={handleContactPhoneSwitchChange} type="checkbox" value="" id="switchContactPhone"/>
                                                        <label className="form-check-label" htmlFor="switchContactPhone">
                                                            Contato por Telefone
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={6} >
                                                <div style={{ display: isCommentPhoneVisible ? 'block' : 'none' }}>
                                                    <label htmlFor="commentsContactPhone" className="form-label">Comentários</label>
                                                    <textarea className="form-control" id="commentsContactPhone"
                                                        name="commentsContactPhone"
                                                        value={formData.commentsContactPhone}
                                                        onChange={handleChange} style={{ height: '100px' }}></textarea>
                                                </div>
                                            </Col>

                                            <Col lg={3}>
                                            {check_TSE === 'Y' && (
                                                    <div>
                                                        <label htmlFor="exampleFormControlTextarea5" className="form-label">Anexar Mídia</label>
                                                        <FilePond
                                                            files={filesTSE}
                                                            onupdatefiles={(fileItems) => setFilesTSE([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                            allowReorder={true}
                                                            allowMultiple={false}
                                                            labelIdle={`
                                                                <div class="d-flex flex-row align-items-center justify-content-center">
                                                                    <img src="/icons/upload.png" />
                                                                    <b style="color: #888; margin-left: 15px">Arraste ou clique para upload da foto</b>
                                                                </div>
                                                            `}
                                                            id="filetseUpload"
                                                            name="filetseUpload"
                                                        />
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={6}>
                                                <div>
                                                    <label htmlFor="comments" className="form-label">Comentários</label>
                                                    <textarea className="form-control" id="comments"
                                                        name="comments"
                                                        value={formData.comments}
                                                        onChange={handleChange} style={{ height: '150px' }}></textarea>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <label className="form-label">Cadastrado por</label>

                                                    {/* Agora Indicação é Obrigatório */}
                                                    {/* <div className="">
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-primary mb-4"
                                                            style={{ marginRight: 16 }}
                                                            onClick={() => handleClickChangeCreatedByType('user')}
                                                            disabled={createdByType !== 'free' && createdByType !== 'recommendation'}
                                                        >
                                                            Usuário
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-primary mb-4"
                                                            onClick={() => handleClickChangeCreatedByType('recommendation')}
                                                            disabled={createdByType !== 'user' && createdByType !== 'free'}
                                                        >
                                                            Indicação
                                                        </button>
                                                    </div> */}

                                                    {/* Usuario nao logado, mostra todas as opções para definir como cadastrado por...  */}
                                                    {!userLogged ? (
                                                        <>
                                                            {createdByType === 'user' && (
                                                                <Select
                                                                    options={usersSelect.map((option) => ({
                                                                        value: option.id,
                                                                        label: option.name,
                                                                    })) as any}
                                                                    value={selectedUser}
                                                                    onChange={handleUserChange}
                                                                    placeholder="Selecione um usuário"
                                                                    noOptionsMessage={({ inputValue }) => 'Nenhum resultado encontrado'}
                                                                    loadingMessage={({ inputValue }) => "Carregando"}
                                                                />
                                                            )}

                                                            {createdByType === 'free' && (
                                                                <input type="text" className="form-control" id="created_by_description"
                                                                    placeholder="Digite o nome"
                                                                    name="created_by_description"
                                                                    value={formData.created_by_description}
                                                                    onChange={handleChange}
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                        
                                                        <Select
                                                            options={[{
                                                                value: userLogged.id,
                                                                label: userLogged.name,
                                                            }]}
                                                            value={{
                                                                value: userLogged.id,
                                                                label: userLogged.name,
                                                            }}
                                                            isDisabled={true}
                                                            onChange={handleUserChange}
                                                            placeholder="Selecione um usuário"
                                                            noOptionsMessage={({ inputValue }) => 'Nenhum resultado encontrado'}
                                                            loadingMessage={({ inputValue }) => "Carregando"}
                                                        />

                                                        </>
                                                    )}
                                                </div>
                                                <Row>
                                                    <Col lg={6}>
                                                        <label className="mt-3 form-label">Cadastrado em: {format(new Date(createdAt), "dd/MM/yyyy - HH'h':mm")}</label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light mt-3">Salvar</button>
                                    </Form>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
