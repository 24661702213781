import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form, ModalHeader, ModalBody, Spinner, Input, FormFeedback } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import Swal from 'sweetalert2';
import ReactInputMask from 'react-input-mask';

import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
registerPlugin(FilePondPluginGetFile);

import { api } from "../../utils/api";
import { saveAs } from 'file-saver';
import Flatpickr from 'react-flatpickr';
import { flatpickrLocale } from "../../utils/flatpickrLocale";
import Select from "react-select";
import TableListServices from "../Services/GridTablesData";
import { removeNonAlphanumeric } from "../../utils/removeNonAlphanumeric";
import { GenericObject } from "../../types/GenericObject";
import { format } from "date-fns";

export function EditVoters() {
    const { voterId } = useParams();
    const navigate = useNavigate();
    const [optionsGroupId, setOptionsGroupId] = useState<Option[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [filesTSE, setFilesTSE] = useState<File[]>([]);
    const [filesSend, setFilesSend] = useState<File[]>([]);
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isExportingPdf, setIsExportingPdf] = useState(false);
    const [createdByType, setCreatedByType] = useState<'user' | 'free' | 'recommendation'>('user');
    const [createdByUserLink, setCreatedByUserLink] = useState<any>(null);
    const [usersSelect, setUsersSelect] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [selectedGroup, setSelectedGroup] = useState<any>({});
    const [lastSearchedCpf, setLastSearchedCpf] = useState('');
    const [check_TSE, setcheck_TSE] = useState<'N' | 'Y'>('N');
    const [userLogged, setUserLogged] = useState({
      active: "",
      cell: "",
      created_at: "",
      email: "",
      email_verified_at: null,
      id: 1,
      name: "",
      photo: "",
      updated_at: "",
      user_type: ""
    });
    const [isAdmin, setIsAdmin] = useState(false);
    const [createdAt, setCreatedAt] = useState(new Date());
    const [isSearchingCpf, setIsSearchingCpf] = useState(false);
    const [isContactPhoneChecked, setContactPhoneChecked] = useState(false);
    const [isCommentPhoneVisible, setIsCommentPhoneVisible] = useState(false);
  

    // Historico das demandas
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectSortRow, setselectSortRow] = useState('id');
    const [sortDirection, setsortDirection] = useState('desc');
    const [demmands, setDemmands] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setisLoading] = useState(false);

    type Option = {
        id: number;
        name: string;
    };

    const [formData, setFormData] = useState({
        cpf: '',
        name: '',
        phone: '',
        birth_date: '',
        mother_name: '',
        group_id: '',
        voter_registration: '',
        electoral_ward_id: '',
        section: '',
        classification: '',
        zip_code: '',
        address: '',
        number: '',
        address_complement: '',
        neighborhood: '',
        city: '',
        state: '',
        comments: '',
        number_classification: 0,
        created_by: 0,
        created_by_description: '',
        created_by_recommendation:'',
        school: '',
        photo_tse:'',
        check_TSE:'',
        switchContactPhone: false,
        commentsContactPhone: '',
    });
    const [formDataMessage, setFormDataMessage] = useState({
        archive: '',
        message: '',
    });
    const [formValidationMessages, setFormValidationMessages] = useState({
        cpf: '',
        name: '',
        phone: '',
        birth_date: '',
        mother_name: '',
        address: '',
        zip_code: '',
        voter_registration: '',
        electoral_ward_id: '',
        school: '',
        section: '',
        created_by_recommendation: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    };
    
    // Validação de duplicidade: verifica se o CPF já existe
    const isCpfRegistered = async (cpf: string): Promise<boolean> => {
        try {
            const response = await api.get(`/isCpfRegistered/${cpf}`);
            return response.data as boolean; 
        } catch (error) {
            console.error("Erro ao verificar CPF:", error);
            return false; 
        }
    }

    const handleContactPhoneSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactPhoneChecked(event.target.checked);
        setFormData({ ...formData, switchContactPhone: event.target.checked });

        setIsCommentPhoneVisible(event.target.checked)
    };

    const handleChangeCpf = async (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, cpf: event.target.value });

        if (isSearchingCpf) {
            return;
        }

        let isRegistered: boolean;
        isRegistered = await isCpfRegistered(removeNonAlphanumeric(event.target.value))

        // validação de duplicidade: verifica se CPF já existe
        if(isRegistered && event.target.value.length >= 14){
            console.error(`Já existe um cadastro com esse CPF`);
            Swal.fire('Erro', `Já existe um cadastro com esse CPF`, 'error');
            setFormData({ ...formData, cpf: '' });
        }

        if (event.target.value.length < 14 || event.target.value === lastSearchedCpf) {
            return;
        }

        try {
            setIsSearchingCpf(true);
            setLastSearchedCpf(event.target.value);

            const cpfResponse = await api.get(`/getCpf/${removeNonAlphanumeric(event.target.value)}`);

            if (cpfResponse.status && cpfResponse.nome !== 'Test Token') {
                let extraInformations: GenericObject = {};

                if (cpfResponse.nascimento) {
                    const day = cpfResponse.nascimento.substring(0, 2);
                    const month = cpfResponse.nascimento.substring(3, 5);
                    const year = cpfResponse.nascimento.substring(6);
                    extraInformations.birth_date = `${day}/${month}/${year}`;
                }

                if (cpfResponse.mae) {
                    extraInformations.mother_name = String(cpfResponse.mae).toUpperCase();
                }

                if (cpfResponse.genero) {
                    extraInformations.gender = cpfResponse.genero === 'F' ? 'Feminino' : (cpfResponse.genero === 'M' ? 'Masculino' : 'Outros');
                }

                setFormData((prevState) => ({
                    ...prevState,
                    name: String(cpfResponse.nome || '').toUpperCase(),
                    ...extraInformations,
                }));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSearchingCpf(false);
        }
    };

    const handleBirthDateChange = (dateString: string) => {
        setFormData({ ...formData, birth_date: dateString });
    };

    const handleChangeMessage = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormDataMessage({ ...formDataMessage, [e.target.name]: e.target.value });
    };

    // const handleGroupChange = (event: ChangeEvent<HTMLSelectElement>) => {
    //     setFormData({ ...formData, group_id: event.target.value });
    // };
    const handleGroupChange = (option: any) => {
        setSelectedGroup(option);
        setFormData({ ...formData, group_id: option?.value });
    };

    const handleUserChange = (option: any) => {
        setSelectedUser(option ?? null);
        setFormData({ ...formData, created_by: option?.value ?? '', created_by_description: '' });
    };

    useEffect(() => {
        async function getUsers() {
            const responseUsers = await api.get('/getUsersSelect');
            setUsersSelect(responseUsers.data);
        }

        getUsers();
        fetchData();
    }, []);
    const toggleDisabled = () => {
        setIsDisabled(!isDisabled);
    };

    const fetchData = async () => {
        try {
            // pega usuario logado
            const responseUser = await api.get(`/getUserLogged`);
            setUserLogged(responseUser.data ?? '')
            setIsAdmin(userLogged.user_type == "administrator") // verifica se é administrador
            
            const responseAccess = await api.get(`/getAuthIdAccessEdit`);
            if (responseAccess.permissions.voters.access_type != "edit") {
                setIsReadOnly(true);
            }

            const responseGroupSelect = await api.get('/getGroup');
            setOptionsGroupId(responseGroupSelect.data);

            const responseVoter = await api.get(`/getVoter/${voterId}`);
            //setFormData(responseVoter.data);
            setFormData({
                cpf: responseVoter.data.cpf ?? '',
                name: responseVoter.data.name ?? '',
                phone: responseVoter.data.phone ?? '',
                birth_date: responseVoter.data.birth_date_formatted ?? '',
                mother_name: responseVoter.data.mother_name ?? '',
                group_id: responseVoter.data.group_id ?? '',
                voter_registration: responseVoter.data.voter_registration ?? '',
                electoral_ward_id: responseVoter.data.electoral_ward_id ?? '',
                section: responseVoter.data.section ?? '',
                classification: responseVoter.data.classification ?? '',
                zip_code: responseVoter.data.zip_code ?? '',
                address: responseVoter.data.address ?? '',
                number: responseVoter.data.number ?? '',
                address_complement: responseVoter.data.address_complement ?? '',
                neighborhood: responseVoter.data.neighborhood ?? '',
                city: responseVoter.data.city ?? '',
                state: responseVoter.data.state ?? '',
                comments: responseVoter.data.comments ?? '',
                number_classification: responseVoter.data.classification ?? 0,
                created_by: responseVoter.data.created_by ?? 0,
                created_by_description: responseVoter.data.created_by_description ?? '',
                created_by_recommendation: responseVoter.data.created_by_recommendation ?? '',
                school: responseVoter.data.school ?? '',
                photo_tse: responseVoter.data.photo_tse ?? '',
                check_TSE: responseVoter.data.check_TSE ?? 'N',
                switchContactPhone: responseVoter.data.is_contact_phone ?? false,
                commentsContactPhone: responseVoter.data.comment_contact ?? '',
            })
            setCreatedAt(responseUser.data.created_at ?? '')
            setIsCommentPhoneVisible(responseVoter.data.is_contact_phone);
            setContactPhoneChecked(responseVoter.data.is_contact_phone);

            responseGroupSelect.data.forEach((e: any)=>{
                if(e.id == responseVoter.data.group_id)
                    setSelectedGroup({ value: e?.id, label: e?.name });
            })

            if (responseVoter.data.photo) {
                const archive = responseVoter.data.photo || '';
                const response = await fetch(process.env.REACT_APP_API_URL + 'getImage/voter/' + archive);
                // const response = await fetch(process.env.REACT_APP_API_URL + 'getImage/voter/' + responseVoter.data.photo ?? '');
                const data = await response.blob();
                const convertedFile = new File([data], responseVoter.data.photo ?? '', { type: data.type });
                setFiles([convertedFile]);
            }
            if (responseVoter.data.check_TSE == 'Y') {
                setcheck_TSE('Y');
            }
            if (responseVoter.data.photo_tse) {
                const archive = responseVoter.data.photo_tse || '';
                const response = await fetch(process.env.REACT_APP_API_URL + 'getImage/voter/' + archive);
                // const response = await fetch(process.env.REACT_APP_API_URL + 'getImage/voter/' + responseVoter.data.photo_tse ?? '');
                const data = await response.blob();
                const convertedFile = new File([data], responseVoter.data.photo_tse ?? '', { type: data.type });
                setFilesTSE([convertedFile]);
            }
            if (responseVoter.data.created_by && responseVoter.data.created_by_description) {
                // Se tiver created_by e created_by_description significa que o created_by é quem gerou o link e created_by_description é o responsável
                const responseUser = await api.get(`/getUser/${responseVoter.data.created_by}`);
                setCreatedByUserLink(responseUser.data);
                setCreatedByType('free');
            } else if (responseVoter.data.created_by) {
                setCreatedByType('user');
                const responseUser = await api.get(`/getUser/${responseVoter.data.created_by}`);
                setSelectedUser({
                    value: responseUser.data.id,
                    label: responseUser.data.name,
                });
                setCreatedByUserLink(null);
            } else if (responseVoter.data.created_by_description) {
                setCreatedByType('free');
                setCreatedByUserLink(null);
            } else if (responseVoter.data.created_by_recommendation) {
                setCreatedByType('recommendation');
                setCreatedByUserLink(null);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchDemmands();
    }, [currentPage, rowsPerPage, selectSortRow, sortDirection, voterId]);

    const fetchDemmands = async () => {
        try {
            setisLoading(true);
            const response = await api.get('/getDemmand', {
                params: {
                    page: currentPage, // Pass the current page directly
                    perPage: rowsPerPage, // Pass the number of rows per page
                    selectSortRow: selectSortRow, // Pass the number of rows per page
                    sortDirection: sortDirection, // Pass the number of rows per page,
                    voterId,
                },
            });

            setDemmands(response.data.data);
            setTotalRows(response.data.totalCount);
            setisLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEdit = (e: FormEvent) => {
        e.preventDefault();

        const isValid = validateFormData();
        // if (!isValid) {
        //     Swal.fire('Erro', `Verifique os erros do formulário`, 'error');
        //     return false;
        // }

        var formFile = new FormData();
        if (files.length) {
            formFile.append('image', files[0], files[0].name);
        }
        if (filesTSE.length) {
            formFile.append('imageTSE', filesTSE[0], filesTSE[0].name);
        }
        Object.entries(formData).forEach(([key, value]) => {
            formFile.append(key, String(value));
        });

        api.post(`/editVoters/${voterId}`, formFile)
            .then(async response => {
                console.log(response);
                
                if (response == "errorCpf") {
                    Swal.fire('Erro', 'Falha na criação de eleitor! <br> CPF Existente!', 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, cpf: 'Já existe outro eleitor com esse nome' }));
                } else if (response == "errorNameAndPhone") {
                    Swal.fire('Erro', 'Falha na criação de eleitor! <br> Nome e Telefone Existente!', 'error');
                    setFormValidationMessages((prevState) => ({
                        ...prevState,
                        name: 'Já existe outro eleitor com esse nome e telefone',
                        phone: 'Já existe outro eleitor com esse nome e telefone'
                    }));
                } else if (response == "errorPhone") {
                    Swal.fire('Erro', 'Falha na criação de eleitor! <br> Telefone Existente!', 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, phone: 'Já existe outro eleitor com esse telefone' }));
                }else if (response == "errorRegistration") {
                    Swal.fire('Erro', 'Falha na criação de eleitor! <br> Título de eleitor Existente!', 'error');
                    setFormValidationMessages((prevState) => ({ ...prevState, voter_registration: 'Já existe outro eleitor com esse título de eleitor' }));
                } else {
                    Swal.fire('Sucesso', 'Eleitor Atualizado com Sucesso!', 'success');
                }
            })
            .catch(error => {
                // Handle the error response
                Swal.fire('Erro', 'Falha na Atualização de eleito!', 'error');
            });
    };

    function validateFormData() {
        const errors = {
            cpf: '',
            name: '',
            phone: '',
            birth_date: '',
            mother_name: '',
            address: '',
            zip_code: '',
            email: '',
            voter_registration: '',
            electoral_ward_id: '',
            school: '',
            section: '',
            created_by_recommendation: '',
        };

        let hasError = false;

        if (!formData.name) {
            hasError = true;
            errors.name = 'O nome é obrigatório';
        }
        // if (!formData.cpf) {
        //     hasError = true;
        //     errors.cpf = 'O CPF é obrigatório';
        //     console.log('O CPF é obrigatório');
        // }
        if (!formData.phone) {
            hasError = true;
            errors.phone = 'O telefone é obrigatório';
        }
        if (!formData.birth_date) {
            hasError = true;
            errors.birth_date = 'A data de nascimento é obrigatória';
        }
        if (!formData.mother_name) {
            hasError = true;
            errors.mother_name = 'O nome da mãe é obrigatório';
        }
        if (!formData.address) {
            hasError = true;
            errors.address = 'O endereço é obrigatório';
        }
        if (!formData.zip_code) {
            hasError = true;
            errors.zip_code = 'O CEP é obrigatório';
        }
        if (!formData.electoral_ward_id) {
            hasError = true;
            errors.electoral_ward_id = 'A Zona Eleitoral é obrigatória';
        }
        if (!formData.school) {
            hasError = true;
            errors.school = 'A Escola é obrigatória';
        }
        if (!formData.section) {
            hasError = true;
            errors.section = 'A Seção é obrigatória';
        }
        if (!formData.voter_registration) {
            hasError = true;
            errors.voter_registration = 'O Título de eleitor é obrigatório';
        }
        if (!formData.created_by_recommendation) {
            hasError = true;
            errors.created_by_recommendation = 'A Indicação é obrigatória';
        }

        setFormValidationMessages(errors);

        return !hasError;
    }

    const handleEditSend = (e: FormEvent) => {
        e.preventDefault();
        var formFileSend = new FormData();
        if (filesSend.length) {
            formFileSend.append('image', filesSend[0], filesSend[0].name);
        }
        Object.entries(formDataMessage).forEach(([key, value]) => {
            formFileSend.append(key, String(value));
        });
        api.post(`/editVoters/send/${voterId}`, formFileSend)
            .then(response => {
                // Handle the success response
                //navigate('/eleitores');
                Swal.fire('Sucesso', 'Mensagem Enviada!', 'success');
            })
            .catch(error => {
                // Handle the error response
                Swal.fire('Erro', 'Falha na Atualização de eleito!', 'error');
            });
        setShowModal(false);

    };
    document.title = "Eleitores | Geld";
    const [phone, setPhone] = useState("");

    const [selectedSingle, setSelectedSingle] = useState(null);

    function onPhoneChange(e: { target: { rawValue: React.SetStateAction<string>; }; }) {
        setPhone(e.target.rawValue);
    }
    const handleRatingChange = (index: number) => {
        setRating(index);
        setFormData({ ...formData, number_classification: index });
    };

    function handleClickChangeCreatedByType(type: 'user' | 'free' | 'recommendation') {
        let newType: 'user' | 'free' | 'recommendation';

        switch (type) {
            case 'user':
                newType = 'user';
                break;
            case 'free':
                newType = 'free';
                break;
            case 'recommendation':
                newType = 'recommendation';
                break;
            default:
                newType = 'user'; // default to 'user' if an invalid type is provided
                break;
        }
        setCreatedByType(newType);
        setFormData({ ...formData, created_by: 0, created_by_description: '' });
        setSelectedUser(null);
    }
    function handlecheck_TSE(check_TSE: 'N' | 'Y'){
        const newCheckTSE = check_TSE === 'N' ? 'N' : 'Y';
        setFormData({ ...formData, check_TSE: newCheckTSE });
        setcheck_TSE(newCheckTSE);
    }

    const StarRating = ({ classification }: { classification: number }) => {

        var divStyle = {
            fontSize: '25px !important'
        };

        useEffect(() => {
            setRating(classification);
        }, [classification]);

        return (
            <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;

                    return (
                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on " : "off"}
                            onClick={() => handleRatingChange(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span className="star" style={divStyle}>&#9733;</span>
                        </button>
                    );
                })}
                <input
                    type="hidden"
                    id="number_classification"
                    name="number_classification"
                    value={formData.number_classification}
                />
            </div>
        );
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    function tog_standard() {
        setmodal_standard(!modal_standard);
    }
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleChangeCep = async (event: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, zip_code: event.target.value });

        if (event.target.value.length <= 8) {
            return;
        }

        const responseGet = await api.get('/getCep/' + event.target.value);
        if (responseGet != "failed") {
            setFormData((prevState) => ({
                ...prevState,
                address: responseGet.logradouro?.toUpperCase() ?? '',
                number: formData.number ?? '',
                neighborhood: responseGet.bairro?.toUpperCase() ?? '',
                city: responseGet.localidade?.toUpperCase() ?? '',
                state: responseGet.uf?.toUpperCase() ?? '',
                address_complement: responseGet.complemento?.toUpperCase() ?? '',
            }));
        }
    };

    const handleExportPdf = async () => {
        setIsExportingPdf(true);

        const response = await api.get(`/getVoter/${voterId}/pdf`, {
            responseType: 'arraybuffer',
        });

        var pdfDataBlob = new Blob([response], { type: 'application/pdf' });

        saveAs(pdfDataBlob, `Eleitor ${voterId}.pdf`);

        setIsExportingPdf(false);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setCurrentPage(1);
        setRowsPerPage(newRowsPerPage);
    };

    const handleSort = (selectSortRow: any, sortDirection: string) => {
        switch (selectSortRow.id) {
            case 1:
                setselectSortRow('id')
                break;
            case 2:
                setselectSortRow('voter')
                break;
            case 3:
                setselectSortRow('category')
                break;
            case 4:
                setselectSortRow('responsible')
                break;
            case 5:
                setselectSortRow('open_date')
                break;
            case 6:
                setselectSortRow('venc_date')
                break;
            case 7:
                setselectSortRow('priority')
                break;
            case 8:
                setselectSortRow('status')
                break;
            default:
                break;
        }
        setsortDirection(sortDirection)
    }

    const customStylesSelect = {
        control: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#eff2f7' : 'white', // Cor de fundo quando desativado
            borderColor: state.isDisabled ? '#dcdcdc' : '#ced4da', // Cor da borda quando desativado
            color: state.isDisabled ? '#a0a0a0' : 'black', // Cor do texto quando desativado
            boxShadow: 'none', // Remove a sombra padrão
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white', // Cor de fundo do menu
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#b1a7ce' : 'white', // Cor de fundo da opção selecionada
            color: state.isSelected ? 'white' : 'black', // Cor do texto da opção selecionada
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#a0a0a0', // Cor do texto do placeholder
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: 'black', // Cor do texto selecionado
        })
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Editar Eleitor {voterId}</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleEdit}>
                                        <Row className="d-flex align-items-center">
                                            <Col>
                                                <span className="text-danger">* Campos obrigatórios</span>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex justify-content-end pt-2">
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-primary waves-effect waves-light mr-2" 
                                                                onClick={toggleDisabled}
                                                            >
                                                                Editar
                                                            </button> 
                                                        </div>
                                                    </Col>
                                                    { isAdmin ? (
                                                        <Col>
                                                            <div className="d-flex justify-content-end pt-2">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary waves-effect waves-light mr-2"
                                                                    onClick={handleExportPdf}
                                                                >
                                                                    Exportar para PDF
                                                                    {isExportingPdf && (
                                                                        <Spinner size="sm" style={{ marginLeft: 6 }} />
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    ): null
                                                }
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4}></Col>
                                            <Col lg={4} className="d-flex justify-content-center">
                                                <FilePond
                                                    className='filepond filepond-container filepond-photo'
                                                    files={files}
                                                    onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                    allowReorder={true}
                                                    allowMultiple={false}
                                                    labelIdle={`
                                                        <div class="d-flex flex-column align-items-center justify-content-center">
                                                            <img src="/icons/upload.png" />
                                                            <b style="color: #888">Arraste ou clique para upload da foto</b>
                                                        </div>
                                                    `}
                                                    id="fileUpload"
                                                    name="fileUpload"
                                                    disabled={isDisabled}                                                    
                                                    acceptedFileTypes={['image/*']} // Restrict to image files
                                                    stylePanelAspectRatio={(300 / 440).toString()} // Convert the aspect ratio to a string
                                                />
                                            </Col>
                                            <Col lg={4}></Col>
                                        </Row>

                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="cpf" className="form-label">
                                                        CPF
                                                    </label>
                                                    <Cleave
                                                        placeholder="Buscar pelo CPF"
                                                        options={{
                                                            delimiters: ['.', '.', '-'],
                                                            blocks: [3, 3, 3, 2],
                                                            numericOnly: true,
                                                        }}
                                                        className={`form-control ${!!formValidationMessages.cpf ? 'is-invalid' : ''}`}
                                                        id="cpf"
                                                        name="cpf"
                                                        value={formData.cpf}
                                                        onChange={handleChangeCpf}
                                                        disabled={isDisabled}
                                                    />
                                                    {!!formValidationMessages.cpf && (
                                                        <FormFeedback>
                                                            {formValidationMessages.cpf}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="inputName" className="form-label">
                                                        Nome <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="inputName"
                                                        placeholder="Digite seu Nome"
                                                        invalid={!!formValidationMessages.name}
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        readOnly={isReadOnly}
                                                        disabled={isDisabled}
                                                    />
                                                    {!!formValidationMessages.name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="cleave-phone" className="form-label">
                                                        Telefone <span className="text-danger">*</span>
                                                    </label>
                                                    <Cleave
                                                        placeholder="xx xxxxx xxxx"
                                                        options={{
                                                            delimiter: ' ',
                                                            blocks: [2, 5, 4] // [2 digits, 5 digits, 4 digits]
                                                        }}
                                                        className={`form-control ${!!formValidationMessages.phone ? 'is-invalid' : ''}`}
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        readOnly={isReadOnly}
                                                        disabled={isDisabled}
                                                    />
                                                    {!!formValidationMessages.phone && (
                                                        <FormFeedback>
                                                            {formValidationMessages.phone}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="birth_date" className="form-label">
                                                        Data de Nascimento <span className="text-danger">*</span>
                                                    </label>
                                                    <ReactInputMask
                                                        id="birth_date"
                                                        name="birth_date"
                                                        className={`form-control ${!!formValidationMessages.birth_date ? 'is-invalid' : ''}`}
                                                        mask="99/99/9999" // Mask for date input
                                                        placeholder="DD/MM/AAAA" // Placeholder for date input
                                                        onChange={handleChange}
                                                        value={formData.birth_date}
                                                        readOnly={isReadOnly}
                                                        disabled={isDisabled}
                                                    />
                                                    {!!formValidationMessages.birth_date && (
                                                        <FormFeedback>
                                                            {formValidationMessages.birth_date}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="mother_name" className="form-label">
                                                        Nome da mãe <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="mother_name"
                                                        placeholder="Digite o nome"
                                                        name="mother_name"
                                                        value={formData.mother_name}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.mother_name}
                                                        readOnly={isReadOnly}
                                                        disabled={isDisabled}
                                                    />
                                                    {!!formValidationMessages.mother_name && (
                                                        <FormFeedback>
                                                            {formValidationMessages.mother_name}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="handleGroupChange" className="form-label">Grupo</label>
                                                    <Select
                                                        options={optionsGroupId.map((option) => ({
                                                            value: option.id,
                                                            label: option.name,
                                                        }))}
                                                        value={selectedGroup}
                                                        onChange={handleGroupChange}
                                                        placeholder="Selecione um Grupo"
                                                        noOptionsMessage={({ inputValue }) => 'Nenhum resultado encontrado'}
                                                        loadingMessage={({ inputValue }) => "Carregando"}
                                                        styles={customStylesSelect}
                                                        isDisabled={isDisabled}
                                                    />
                                                    {/* <select className="form-select mb-3" id="handleGroupChange" aria-label="Default select example"
                                                        name="group"
                                                        value={formData.group_id}
                                                        onChange={handleGroupChange}
                                                        disabled={isDisabled}>
                                                        <option value="">Selecione um Grupo</option>
                                                        {optionsGroupId.map((option) => (
                                                            <option key={option.id} value={option.id} >
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select> */}
                                                </div>
                                            </Col>
                                            
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="voter_registration" className="form-label">
                                                        Título de eleitor <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text" className="form-control"
                                                        id="voter_registration"
                                                        placeholder="Digite seu Titulo Eleitoral"
                                                        name="voter_registration"
                                                        value={formData.voter_registration}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.voter_registration}
                                                        readOnly={isReadOnly}
                                                        disabled={isDisabled}
                                                    />
                                                    {!!formValidationMessages.voter_registration && (
                                                        <FormFeedback>
                                                            {formValidationMessages.voter_registration}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                            <div>
                                                    <label htmlFor="electoral_ward_id" className="form-label">
                                                        Zona Eleitoral <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="number" className="form-control" id="electoral_ward_id" placeholder="Digite sua Zona"
                                                        name="electoral_ward_id"
                                                        value={formData.electoral_ward_id}
                                                        onChange={handleChange}
                                                        readOnly={isReadOnly}
                                                        disabled={isDisabled}
                                                        invalid={!!formValidationMessages.electoral_ward_id}
                                                    />
                                                    {!!formValidationMessages.electoral_ward_id && (
                                                        <FormFeedback>
                                                            {formValidationMessages.electoral_ward_id}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="section" className="form-label">
                                                        Seção <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="text" className="form-control" id="section" placeholder="Digite sua Referência"
                                                        name="section"
                                                        value={formData.section}
                                                        onChange={handleChange}
                                                        readOnly={isReadOnly}
                                                        disabled={isDisabled}
                                                        invalid={!!formValidationMessages.section}
                                                    />
                                                    {!!formValidationMessages.section && (
                                                        <FormFeedback>
                                                            {formValidationMessages.section}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="school" className="form-label">
                                                        Escola <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="text" className="form-control" id="school" placeholder="Digite sua Escola"
                                                        name="school"
                                                        value={formData.school}
                                                        onChange={handleChange} 
                                                        disabled={isDisabled}
                                                        invalid={!!formValidationMessages.school}/>
                                                        {!!formValidationMessages.school && (
                                                            <FormFeedback>
                                                                {formValidationMessages.school}
                                                            </FormFeedback>
                                                        )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="created_by_recommendation" className="form-label">
                                                        Indicação <span className="text-danger">*</span>
                                                    </label>
                                                    <Input type="text" className="form-control" id="created_by_recommendation"
                                                        placeholder="Digite a Indicação"
                                                        name="created_by_recommendation"
                                                        value={formData.created_by_recommendation}
                                                        onChange={handleChange}
                                                        disabled={isDisabled}
                                                        invalid={!!formValidationMessages.created_by_recommendation}/>
                                                    {!!formValidationMessages.created_by_recommendation && (
                                                        <FormFeedback>
                                                            {formValidationMessages.created_by_recommendation}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="zip_code" className="form-label">
                                                        CEP <span className="text-danger">*</span>
                                                    </label>
                                                    <Cleave
                                                        placeholder="Buscar CEP"
                                                        options={{
                                                            delimiter: '-',
                                                            blocks: [5, 3],
                                                            numericOnly: true,
                                                        }}
                                                        className={`form-control ${!!formValidationMessages.zip_code ? 'is-invalid' : ''}`}
                                                        id="zip_code"
                                                        name="zip_code"
                                                        value={formData.zip_code}
                                                        onChange={handleChangeCep}
                                                        readOnly={isReadOnly}
                                                        disabled={isDisabled}
                                                    />
                                                    {!!formValidationMessages.zip_code && (
                                                        <FormFeedback>
                                                            {formValidationMessages.zip_code}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <label htmlFor="address" className="form-label">
                                                        Endereço <span className="text-danger">*</span>
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                        invalid={!!formValidationMessages.address}
                                                        disabled={isDisabled}
                                                    />
                                                    {!!formValidationMessages.address && (
                                                        <FormFeedback>
                                                            {formValidationMessages.address}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="number" className="form-label">Número</label>
                                                    <input type="number" className="form-control" id="number"
                                                        name="number"
                                                        value={formData.number}
                                                        onChange={handleChange}
                                                        disabled={isDisabled}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="address_complement" className="form-label">Complemento</label>
                                                    <input type="text" className="form-control" id="address_complement"
                                                        name="address_complement"
                                                        value={formData.address_complement}
                                                        onChange={handleChange}
                                                        disabled={isDisabled}                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="neighborhood" className="form-label">Bairro</label>
                                                    <input type="text" className="form-control" id="neighborhood"
                                                        name="neighborhood"
                                                        value={formData.neighborhood}
                                                        onChange={handleChange}
                                                        disabled={isDisabled} />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="city" className="form-label">
                                                        Cidade
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="city"
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={handleChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="state" className="form-label">
                                                        Estado (UF)
                                                    </label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="state"
                                                        name="state"
                                                        value={formData.state}
                                                        onChange={handleChange}
                                                        maxLength={2}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="check_TSE" className="form-label">Confere TSE</label>
                                                    <select className="form-select mb-3" id="check_TSE" aria-label="Default select example"
                                                        name="check_TSE"
                                                        value={formData.check_TSE}
                                                        disabled={isDisabled}
                                                        onChange={(e) => handlecheck_TSE(e.target.value as 'N' | 'Y')}>
                                                        <option value="N">Não</option>
                                                        <option value="Y">Sim</option>
                                                    </select>
                                                </div>
                                            </Col>

                                            <Col lg={3} className="mt-3">
                                                <div>
                                                    <label htmlFor="switchContactPhone" className="form-label"></label>
                                                    
                                                    <div className="form-check">
                                                        <input className="form-check-input" disabled={isDisabled} onChange={handleContactPhoneSwitchChange} checked={isContactPhoneChecked ? true : false} type="checkbox" value="" id="switchContactPhone"/>
                                                        <label className="form-check-label" htmlFor="switchContactPhone">
                                                            Contato por Telefone
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={6} >
                                                <div style={{ display: isCommentPhoneVisible ? 'block' : 'none' }}>
                                                    <label htmlFor="commentsContactPhone" className="form-label">Comentários</label>
                                                    <textarea className="form-control" id="commentsContactPhone"
                                                        name="commentsContactPhone"
                                                        value={formData.commentsContactPhone}
                                                        onChange={handleChange} style={{ height: '100px' }}
                                                        disabled={isDisabled}></textarea>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                            {check_TSE === 'Y' && (
                                                    <div>
                                                        <label htmlFor="exampleFormControlTextarea5" className="form-label">Anexar Mídia</label>
                                                        <FilePond
                                                            files={filesTSE}
                                                            onupdatefiles={(fileItems) => setFilesTSE([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                            allowReorder={true}
                                                            allowMultiple={false}
                                                            
                                                            labelIdle={`
                                                                <div class="d-flex flex-row align-items-center justify-content-center">
                                                                    <img src="/icons/upload.png" />
                                                                    <b style="color: #888; margin-left: 15px">Arraste ou clique para upload da foto</b>
                                                                </div>
                                                            `}
                                                            id="filetseUpload"
                                                            name="filetseUpload"
                                                            disabled={isDisabled}
                                                            allowDownloadByUrl={true}
                                                        />
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col lg={6}>
                                                <div>
                                                    <label htmlFor="comments" className="form-label">Comentários</label>
                                                    <textarea className="form-control" id="comments"
                                                        name="comments"
                                                        value={formData.comments}
                                                        onChange={handleChange} style={{ height: '150px' }}
                                                        disabled={isDisabled}></textarea>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div>
                                                    <label className="form-label">Cadastrado por</label>

                                                    {/* <div className="">
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-primary mb-4"
                                                            style={{ marginRight: 16 }}
                                                            onClick={() => handleClickChangeCreatedByType('user')}
                                                            disabled={createdByType !== 'free' && createdByType !== 'recommendation'}
                                                        >
                                                            Usuário
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-primary mb-4"
                                                            onClick={() => handleClickChangeCreatedByType('recommendation')}
                                                            disabled={createdByType !== 'user' && createdByType !== 'free'}
                                                        >
                                                            Indicação
                                                        </button>
                                                    </div> */}

                                                    {/* Usuario nao logado, mostra todas as opções para definir como cadastrado por...  */}
                                                    {!userLogged ? (
                                                        <>
                                                            {createdByType === 'user' && (
                                                                <Select
                                                                    options={usersSelect.map((option) => ({
                                                                        value: option.id,
                                                                        label: option.name,
                                                                    })) as any}
                                                                    value={selectedUser}
                                                                    onChange={handleUserChange}
                                                                    placeholder="Selecione um usuário"
                                                                    noOptionsMessage={({ inputValue }) => 'Nenhum resultado encontrado'}
                                                                    loadingMessage={({ inputValue }) => "Carregando"}
                                                                />
                                                            )}

                                                            {createdByType === 'free' && (
                                                                <input type="text" className="form-control" id="created_by_description"
                                                                    placeholder="Digite o nome"
                                                                    name="created_by_description"
                                                                    value={formData.created_by_description}
                                                                    onChange={handleChange}
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                        
                                                        <Select
                                                            options={[{
                                                                value: userLogged.id,
                                                                label: userLogged.name,
                                                            }]}
                                                            value={{
                                                                value: userLogged.id,
                                                                label: userLogged.name,
                                                            }}
                                                            isDisabled={true}
                                                            onChange={handleUserChange}
                                                            placeholder="Selecione um usuário"
                                                            noOptionsMessage={({ inputValue }) => 'Nenhum resultado encontrado'}
                                                            loadingMessage={({ inputValue }) => "Carregando"}
                                                        />

                                                        </>
                                                    )}

                                                    {!!createdByUserLink && (
                                                        <div className="d-block mt-3">
                                                            Criado via link gerado pelo usuario <strong>{createdByUserLink.name}</strong>
                                                        </div>
                                                    )}
                                                </div>

                                                <Row>
                                                    <Col lg={6}>
                                                        <label className="mt-3 form-label">Cadastrado em: {format(new Date(createdAt), "dd/MM/yyyy - HH'h':mm")}</label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="d-flex justify-content-between pt-2 mt-2">
                                                    <button type="submit" className="btn btn-primary waves-effect waves-light mr-2">Salvar</button>
                                                    <button type="button" className="btn btn-primary" onClick={handleShowModal}>Enviar Mensagem Direta</button>

                                                </div>
                                            </Col>
                                            <Col>
                                            </Col>
                                            <Col></Col>
                                        </Row>

                                    </Form>
                                    <div>

                                        <Modal show={showModal} onHide={handleCloseModal} size="lg">
                                            <Form onSubmit={handleEditSend}>
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div>
                                                                <label htmlFor="message" className="form-label">Mensagem</label>
                                                                <textarea className="form-control" id="message" name="message" onChange={handleChangeMessage} value={formDataMessage.message} style={{ height: '100px' }}></textarea>
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <label htmlFor="fileUpload" className="form-label">Anexar Mídia</label>
                                                            <FilePond
                                                                files={filesSend}
                                                                onupdatefiles={(fileItems) => setFilesSend([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                                allowReorder={true}
                                                                allowMultiple={false}
                                                                labelIdle='Anexar Mídia'
                                                                id="fileUpload"
                                                                name="fileUpload"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button className="btn btn-primary" onClick={handleCloseModal}>
                                                        Fechar
                                                    </button>
                                                    <button type="submit" className="btn btn-primary">
                                                        Enviar Mensagem
                                                    </button>
                                                </Modal.Footer>
                                            </Form>
                                        </Modal>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Demandas</h4>
                                    </div>
                                </CardHeader>

                                <CardBody>
                                    <TableListServices
                                        tableData={demmands}
                                        rowsPerPage={rowsPerPage}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        total={totalRows}
                                        isLoading={isLoading}
                                        handleSort={handleSort}
                                        onExportExcel={false}
                                        onExportPdf={false}
                                        showVoters={false}
                                        canDelete={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Default Modal */}
            <Modal id="myModal"
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <ModalHeader className="modal-title" id="myModalLabel" toggle={() => {
                    tog_standard();
                }}>
                    Modal Heading
                </ModalHeader>
                <ModalBody>
                    <h5 className="fs-15">
                        Overflowing text to show scroll behavior
                    </h5>
                    <p className="text-muted">One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections.</p>
                    <p className="text-muted">The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What's happened to me?" he thought.</p>
                    <p className="text-muted">It wasn't a dream. His room, a proper human room although a little too small, lay peacefully between its four familiar walls.</p>
                </ModalBody>
                <div className="modal-footer">
                    <button
                        color="light"
                        onClick={() => {
                            tog_standard();
                        }}
                    >
                        Close
                    </button>
                    <button
                        color="primary"
                    >
                        Save changes
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    );
}
